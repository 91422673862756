import { createContext, useContext } from "react";

interface SymContext {
  username: string | null;
  token: string;
}

const symContext = createContext<SymContext>({ username: null, token: '' });
export const SymContextProvider = symContext.Provider;
export const useSymContext = () => useContext(symContext);
